import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import slide_1 from "./img/ALpha B - My Help is on the way.jpg";
import slide_2 from "./img/Alpha B - Almighty God.jpg";
import slide_3 from "./img/Alpha B - With You.jpg";
import slide_4 from "./img/TOCCI - Kiss and tell.jpg";
import slide_5 from "./img/TOCCI - No time.jpg";
import slide_6 from "./img/TOCCI - 6 feet.jpg";
import slide_7 from "./img/Echo boyy - Only once.jpg";
import slide_8 from "./img/Echo boyy - Cash on drop.jpg";
import slide_9 from "./img/RNG - Fantastick.jpg";
import slide_10 from "./img/RNB - Playboy.jpg";
import performer1 from "./img/performer1.jpg";
import performer2 from "./img/performer2.jpg";
import performer3 from "./img/performer3.jpg";
import performer4 from "./img/performer4.jpg";
import performer5 from "./img/performer5.jpg";
import {EMusicPlatforms} from "../../utils/iApp";
import Slider from "react-slick";
import Section2List from "./Section2List";



interface Props {
}

const Section2: FC<Props> = () => {
  const {t} = useTranslation();
  return (
    <section className="performers">
      <Section2List className='delay' />
      <Section2List  />
    </section>
  );
}

export default Section2;