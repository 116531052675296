import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import check_circle_svg from "../../assets/img/icon-check.svg";
import logo_svg from "../../assets/img/logo.png";

interface Props {
}

const SectionFormSuccess: FC<Props> = () => {
  const {t} = useTranslation();
  return (
    <div className='section_5-success-form'>
      <div className='section_5-success-form-title'>
        <ReactSVG src={check_circle_svg}/>
        <span className='bold ps-2'>{t('SUCCESS_FORM_TITLE')}</span>
      </div>
      <p dangerouslySetInnerHTML={{__html: t('SUCCESS_FORM_TEXT') || ''}} />
      <div className='section_5-success-form-logo'><ReactSVG src={logo_svg}/></div>
    </div>
  );
}

export default SectionFormSuccess;