import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import logo from '../../assets/img/logo.png';
import {login_href} from "../../utils/links";
import LangSelect from "../LangSelect";

interface Props {
}

const Header: FC<Props> = () => {
  const {t} = useTranslation();
  const [scrolling, setScrolling] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setScrolling(scrollY >= 20)
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className="header">
      <div className="wrapper">
        <div className="header__logo">
          <img src={logo} alt="logo" />
        </div>
        <a href="#join-form" className="btn">{t('FILL_JOIN')}</a>
        <div className="header__menu">
          {/*<a href="" className="header__lang">EN</a>*/}
          <LangSelect />
          <a href={login_href} className="header__login">{t('LOGIN')}</a>
        </div>
      </div>
    </header>
  );
}

export default Header;
