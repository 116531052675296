import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import {POLICY, TERMS} from "../../utils/links";

interface Props {
}

const Footer: FC<Props> = () => {
  const {t} = useTranslation();
  return (
    <footer className="footer">
      <div className="footer__copyright">
        <div>Copyright © 2023 Arpeggy (Brom Networks Ltd)</div>
        <div style={{marginTop: 4}}>63-66 Hatton Garden, Fifth Floor, Suite 23, London, United Kingdom, EC1N 8LE</div>
      </div>
      <div className="footer__contact">Contact us at <a href="mailto:brom.networks@gmail.com"
                                                        target='_blank'>brom.networks@gmail.com</a></div>
      <div className="footer__privacy">
        <a href={POLICY} target='_blank'>{t('POLICY')}</a>
        <a href={TERMS} target='_blank'>{t('TERMS')}</a>
      </div>
    </footer>
  );
}

export default Footer;