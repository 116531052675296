import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";

import './lang_select.css';
import OutsideClick from "../OutsideClick";
import {availableLangs} from "../../utils/utils";

interface Props {
}

const LangSelect: FC<Props> = () => {
  const {t, i18n} = useTranslation();

  const [visible, setVisible] = useState(false);

  const showList = () => setVisible(true);
  const hideList = () => setVisible(false);

  const handleClickLang = (ln: string) => () => {
    i18n.changeLanguage(ln);
    setVisible(false);
  };

  const activeLn = i18n.language;

  if (availableLangs.length === 1) return null;
  return (
    <OutsideClick outsideClickEvent={hideList} className={`lang-select${visible ? ' opened' : ''}`}>
      <div className='lang-select-visible' onClick={showList}>
        <div className='header__lang'>{activeLn}</div>
      </div>
      <div className='custom-search-select-list'>
        {availableLangs.map((ln, i) => (
          <div
            key={i}
            onClick={handleClickLang(ln)}
            className={`custom-search-select-list-item${ln === activeLn ? ' active' : ''}`}
          >
            {ln}
          </div>
        ))}
      </div>
    </OutsideClick>
  );
}

export default LangSelect;
