import React, {FC} from 'react';
import {useTranslation} from "react-i18next";


interface Props {
}

const Section4: FC<Props> = () => {
  const {t} = useTranslation();
  return (
    <>
      <section className="platforms">
        <div className="wrapper">
          <h2 className="title" dangerouslySetInnerHTML={{__html: t('SECTION_3_TITLE') || ''}} />
          <ul className="platforms__list">
            <li className="platforms__item platforms__item_spotify"></li>
            <li className="platforms__item platforms__item_wide platforms__item_deezer"></li>
            <li className="platforms__item platforms__item_amazon"></li>
            <li className="platforms__item platforms__item_shazam"></li>
            <li className="platforms__item platforms__item_tall platforms__item_youtube platforms__item_desktop"></li>
            <li className="platforms__item platforms__item_wide platforms__item_google"></li>
            <li className="platforms__item platforms__item_youtube-music platforms__item_mobile"></li>
            <li className="platforms__item platforms__item_youtube-red platforms__item_mobile"></li>
            <li className="platforms__item platforms__item_apple"></li>
            <li className="platforms__item  platforms__item_wide platforms__item_yandex"></li>
          </ul>
        </div>
      </section>
      <section className="charge">
        <div className="wrapper">
          <h2 className="title" dangerouslySetInnerHTML={{__html: t('SECTION_4_TITLE') || ''}} />
          <ul className="charge__list">
            <li className="charge__item charge__item1">
              <p className="charge__item-name">{t('SECTION_4_ITEM_1_TITLE')}</p>
              <p className="charge__item-text">{t('SECTION_4_ITEM_1_TEXT')}</p>
            </li>
            <li className="charge__item charge__item2">
              <p className="charge__item-name">{t('SECTION_4_ITEM_2_TITLE')}</p>
              <p className="charge__item-text">{t('SECTION_4_ITEM_2_TEXT')}</p>
            </li>
            <li className="charge__item charge__item3">
              <p className="charge__item-name">{t('SECTION_4_ITEM_3_TITLE')}</p>
              <p className="charge__item-text">{t('SECTION_4_ITEM_3_TEXT')}</p>
            </li>
          </ul>
        </div>
      </section>
      <section className="expand expand2">
        <div className="wrapper">
          <h1 className="title">{t('SECTION_4_TEXT_4')}</h1>
          <a href="#join-form" className="btn btn-desktop">{t('FILL_JOIN')}</a>
        </div>
      </section>
      <section className="btn-mobile btn-mobile2"><a href="#join-form" className="btn">{t('FILL_JOIN')}</a></section>
      <section className="extra">
        <div className="wrapper">
          <div className="extra__item extra__item_exclusive">
            <h3 className="extra__item-title">{t('SECTION_4_TITLE_2')}</h3>
            <p className="extra__item-text">{t('SECTION_4_TEXT_2')}</p>
          </div>
          <div className="extra__item extra__item_more">
            <h3 className="extra__item-title">{t('SECTION_4_TITLE_3')}</h3>
            <p className="extra__item-text">{t('SECTION_4_TEXT_3_1')}</p>
            <p className="extra__item-text">{t('SECTION_4_TEXT_3_2')}</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Section4;