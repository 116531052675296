import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";
import SectionFormSuccess from "./SectionFormSuccess";
import SectionForm from "./SectionForm";

interface Props {
}

const Section5: FC<Props> = () => {
  const {t} = useTranslation();
  const [success, setSuccess] = useState(false);

  return (
    <section className="join" id='join-form'>
      <div className="wrapper">
        <h2 className="title">Join Invitation</h2>
        <p className="join__info">To apply for inclusion in our label, please fill out the form.</p>
        {success
          ?
          <SectionFormSuccess/>
          :
          <SectionForm onSuccess={() => setSuccess(true)}/>
        }
      </div>
    </section>
  );
}

export default Section5;