import React, {FC, useEffect, useLayoutEffect} from 'react';
import {useTranslation} from "react-i18next";


interface Props {
}



function isInUpperHalf(element: any) {
  const elementRect = element.getBoundingClientRect();
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

  return elementRect.top <= viewportHeight / 2;
}

const Section3: FC<Props> = () => {
  const {t} = useTranslation();

  useEffect(() => {
    const targetElements = document.querySelectorAll('.features__item');
    const handleScroll = () => {
      targetElements.forEach(element => {
        if (isInUpperHalf(element)) {
          element.classList.add('highlighted');
        } else {
          element.classList.remove('highlighted');
        }
      });
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <section className="btn-mobile"><a href="#join-form" className="btn">{t('FILL_JOIN')}</a></section>
      <section className="expand">
        <div className="wrapper">
          <h1 className="title">{t('SECTION_4_TEXT_4')}</h1>
          <a href="#join-form" className="btn btn-desktop">{t('FILL_JOIN')}</a>
        </div>
      </section>
      <section className="features">
        <div className="wrapper">
          <ul className="features__list">
            <li className="features__item">
              <span className="features__item-name">{t('SECTION_2_ITEM_1_TITLE')}</span>
              <span className="features__item-value">{t('SECTION_2_ITEM_1_TEXT')}</span>
            </li>
            <li className="features__item">
              <span className="features__item-name">{t('SECTION_2_ITEM_2_TITLE')}</span>
              <span className="features__item-value">{t('SECTION_2_ITEM_2_TEXT')}</span>
            </li>
            <li className="features__item">
              <span className="features__item-name">{t('SECTION_2_ITEM_3_TITLE')}</span>
              <span
                className="features__item-value">{t('SECTION_2_ITEM_3_TEXT')}</span>
            </li>
          </ul>
        </div>
      </section>

    </>
  );
}

export default Section3;