import React, {FC, useCallback, useEffect, useState} from 'react';
import {InputFile} from "../../components/FormControls";
import {useTranslation} from "react-i18next";
import {EService, IAsset, IPerformerApplicationSubmitRequest} from "../../utils/rest";
import {API} from "../../utils/api";
import {toast} from "../../utils/utils";
import {POLICY, TERMS} from "../../utils/links";

interface Props {
  onSuccess: () => void;
}

const SectionForm: FC<Props> = ({onSuccess}) => {
  const {t} = useTranslation();
  const [form, setForm] = useState<IPerformerApplicationSubmitRequest>({
    name: '',
    email: '',
    contacts: '',
    samples: [],
    bio: '',
    questions: '',
    unpublishedTracks: '',
    originalBeats: 'no',
  });
  const [step, setStep] = useState(0);
  const [contacts, setContacts] = useState({whatsapp: '', tg: '', viber: ''});
  const [contactsError, setContactsError] = useState(false);
  const [sampleError, setSampleError] = useState(false);
  const [samples, setSamples] = useState<IAsset[]>([]);
  const [checkbox, setCheckbox] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let utm_source = urlParams.get('utm_source');
    if (utm_source) {
      localStorage.setItem('utm_source', utm_source);
    }
  }, []);

  const handleChangeStep = useCallback((step: number) => () => {
    setStep(step);
    const el = document.querySelector(`#group${step + 1}`);
    el?.scrollIntoView({behavior: 'smooth'});
  }, []);

  const handleChange = useCallback((key: any) => (e: any) => {
    setForm(prevState => {
      return {...prevState, [key]: e.target.value}
    })
  }, []);

  const handleChangeContacts = useCallback((contact: string) => (e: any) => {
    setContactsError(false);
    setContacts(prevState => {
      return {...prevState, [contact]: e.target.value}
    });
  }, []);

  const handleUpload = useCallback((sample: IAsset, remove?: boolean) => {
    if (remove) {
      setSamples(prevState => {
        const idx = prevState.findIndex(item => item.id === sample.id);
        return [...prevState.slice(0, idx), ...prevState.slice(idx + 1)]
      });
    } else {
      setSamples(prevState => {
        return [...prevState, sample]
      })
    }
    setSampleError(false);
  }, []);

  const handleSubmit = useCallback(async (e: any) => {
    e.preventDefault();
    if (loading) return;
    if (!samples?.length) return setSampleError(true);
    try {
      let resContacts = '';
      let contactError = true;
      Object.entries(contacts).forEach(([key, value], i) => {
        if (!value) return;
        contactError = false;
        resContacts += `${i ? ', ' : ''}${key}: ${value}`
      });
      if (contactError) return setContactsError(true);
      setLoading(true);
      await API.Applications.submitPerformerApplication({
        ...form,
        samples: samples.map(item => item.id),
        contacts: resContacts,
        service: EService.Arpeggy,
        source: localStorage.getItem('utm_source') || null,
      });
      onSuccess();
      //@ts-ignore
      ym(94487142, 'reachGoal', 'submit')
    } catch (e: any) {
      toast(e)
    } finally {
      setLoading(false);
    }
  }, [form, samples, onSuccess, contacts, loading]);

  const formErrorStep1 = !form.name || !form.email || !samples?.length;
  const formErrorStep2 = formErrorStep1 || !form.unpublishedTracks || !form.bio;
  const formErrorStep3 = formErrorStep2 || !checkbox;
  const formEmptyContacts = !contacts.whatsapp && !contacts.tg && !contacts.viber;

  return (
    <form className="join__form" onSubmit={handleSubmit}>
      <div className="join__form-group join__form-group-active">
        <div className="join__form-top">
          <label className="join__form-name">
            {t('NAME')}<sup>*</sup>
            <input
              className="join__form-input input1"
              id="form-name"
              type="text"
              name="name"
              required
              value={form.name}
              placeholder={t('NAME_PLACEHOLDER') || ''}
              onChange={handleChange('name')}
            />
          </label>
          <label className="join__form-email">
            {t('EMAIL')}<sup>*</sup>
            <input
              className="join__form-input input1"
              id="form-email"
              type="email"
              name="email"
              placeholder={t('EMAIL_PLACEHOLDER') || ''}
              required
              value={form.email}
              onChange={handleChange('email')}
            />
          </label>
        </div>
        <div className="join__form-music">
          <div className="join__form-attach">
            <InputFile
              placeholder={t('SAMPLES_TRACKS_PLACEHOLDER') || ''}
              required
              label={'SAMPLES_TRACKS'}
              value={form.samples}
              onChange={handleUpload}
              acceptText='Attach wav / mp3'
            />
            <ul id="fileList" className="file-list">
              {samples?.map((file, i) => (
                <li key={i}>
                  <div className='text-truncate'>{file.name}</div>
                </li>
              ))}
            </ul>
          </div>
          <div className="join__form-original">
            <label htmlFor="original-select" className="join__form-original-name">
              {t('ORIGINAL_BEATS')}<sup>*</sup></label>
            <select
              className="join__form-select input1"
              name="original-select"
              id="original-select"
              required
              value={form.originalBeats}
              onChange={handleChange('originalBeats')}
            >
              <option value="no">{t('NO')}</option>
              <option value="yes">{t('YES')}</option>
            </select>
          </div>
        </div>
        <div className="join__form_btn-wrapper">
          <button
            type='button'
            onClick={handleChangeStep(1)}
            className="btn join__form_btn"
            id="form-btn-1"
            disabled={formErrorStep1}
          >
            {t('TO_SOC_LINKS')}
          </button>
        </div>
      </div>
      <div className={`join__form-group${step >= 1 ? ' join__form-group-active' : ''}`} id="group2">
        <div className="join__form-top">
          <label className="join__form-extra" id="form-extra">
            {t('SOC_LINKS')}<sup>*</sup>
            <textarea
              value={form.bio}
              onChange={handleChange('bio')}
              className="join__form-textarea input2" id="form-message"
              placeholder={t('SOC_LINKS_PLACEHOLDER') || ''}
              required/>
          </label>
        </div>
        <div className="join__form-more">
          <label className="join__form-questions">
            {t('QUESTIONS')}
            <input
              className="join__form-input input2"
              type="text"
              name="questions"
              placeholder={t('QUESTIONS_PLACEHOLDER') || ''}
              value={form.questions || ''}
              onChange={handleChange('questions')}
            />
          </label>
          <label className="join__form-tracks" id="form-tracks">
            {t('UNPUBLISHED_TRACKS_LABEL')}<sup>*</sup>
            <input
              className="join__form-input input2"
              id="form-unreleased"
              type="text"
              name="tracks"
              required
              value={form.unpublishedTracks}
              onChange={handleChange('unpublishedTracks')}
            />
          </label>
        </div>
        <div className="join__form_btn-wrapper">
          <button
            type='button'
            className="btn join__form_btn"
            id="form-btn-2"
            onClick={handleChangeStep(2)}
            disabled={formErrorStep2}
          >{t('TO_MESSENGER')}</button>
        </div>
      </div>
      <div className={`join__form-group join__form-group_socials${step >= 2 ? ' join__form-group-active' : ''}`}
           id="group3">
        <label className="join__form-whatsapp">
          Whatsapp
          <input
            required={formEmptyContacts}
            placeholder={t('WHATSAPP_PLACEHOLDER') || ''}
            className={`join__form-input${contactsError ? ' error' : ''}`}
            type="text"
            name="whatsapp"
            value={contacts.whatsapp}
            onChange={handleChangeContacts('whatsapp')}
          />
        </label>
        <label className="join__form-telegram">
          Telegram
          <input
            required={formEmptyContacts}
            placeholder={t('TG_PLACEHOLDER') || ''}
            className={`join__form-input${contactsError ? ' error' : ''}`}
            type="text"
            name="telegram"
            value={contacts.tg}
            onChange={handleChangeContacts('tg')}
          />
        </label>
        <label className="join__form-viber">
          Viber
          <input
            required={formEmptyContacts}
            placeholder={t('VIBER_PLACEHOLDER') || ''}
            className={`join__form-input${contactsError ? ' error' : ''}`}
            type="text"
            name="viber"
            value={contacts.viber}
            onChange={handleChangeContacts('viber')}
          />
        </label>
      </div>
      <div className="join__form-end">
        <label className="join__form-agree">
          <input className="join__form-checkbox" type="checkbox" id="join__form-terms" required checked={checkbox}
                 onChange={(e) => setCheckbox(e.target.checked)}/>
          <div className="join__form-checkbox-custom"></div>
          <div className="join__form-terms-label"
               dangerouslySetInnerHTML={{__html: t('POLICY_PERMISSION', {policy: POLICY, terms: TERMS}) || ''}}/>
        </label>
        <button className="btn join__form_btn" id="form-submit"
                disabled={formErrorStep3}
        >{t('FILL_JOIN_1')}
        </button>
      </div>
    </form>
  );
}

export default SectionForm;
