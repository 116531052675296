import React, {FC} from 'react';
import performer1 from "./img/performer1.jpg";
import performer2 from "./img/performer2.jpg";
import performer3 from "./img/performer3.jpg";
import performer4 from "./img/performer4.jpg";

interface Props {
  className?: string;
}
const mochaData2 = [ performer1, performer2, performer3, performer4]
const Section2List: FC<Props> = ({className}) => {
  return (
    <div className={`performers__list ${className || ''}`}>
      {mochaData2.map((item, i) => (
        <div className="performers__item" key={i}>
          <img src={item} alt=""/>
          <div className="performers__item-desc">
            <div className="performers__item-text">
              <p className="performers__item-track">Fear No Evil</p>
              <p className="performers__item-name">Royal Beatz</p>
              {/*<p className="performers__item-track">{item.name}</p>*/}
              {/*<p className="performers__item-name">{item.author}</p>*/}
            </div>
            <div className="performers__item-links">
              <a href="" className="performers__item-spotify"></a>
              <a href="" className="performers__item-deezer"></a>
              <a href="" className="performers__item-yandex"></a>
              <a href="" className="performers__item-shazam"></a>
              {/*{Object.entries(item.links).map(([platform, link], i) => (*/}
              {/*  <a href={link} className={`performers__item-${platform}`} key={i}/>*/}
              {/*))}*/}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Section2List;