import React, {FC} from 'react';
import {useTranslation} from "react-i18next";


import Slider from "react-slick";


interface Props {
}


const Section1: FC<Props> = () => {
  const {t} = useTranslation();

  const settings = {
    arrows: false,
    dots: true,
    autoplay: true,
    infinite: true
  };
  return (
    <section className="mission">
      <div className="wrapper">
        <div className="mission__list">
          <Slider {...settings}>
            <div className="mission__item">
              <p className="mission__item-main" dangerouslySetInnerHTML={{__html: t('SECTION_1_TITLE_0') || ''}}/>
              <p className="mission__item-desc">{t('SECTION_1_TEXT_0')}</p>
            </div>
            <div className="mission__item">
              <p className="mission__item-main" dangerouslySetInnerHTML={{__html: t('SECTION_1_TITLE_1') || ''}}/>
              <p className="mission__item-desc">{t('SECTION_1_TEXT_1')}</p>
            </div>
          </Slider>
        </div>

      </div>
    </section>
  );
}

export default Section1;